import React from 'react'
import { Column } from '../../layout/components/dom'
import { useGlobal } from '../../utility/CTX'
import { ContactButton } from '../../layout/components/button';

function FAQ() {
    const { theme } = useGlobal();



    // pull the chagnes from the firebase database...
    // changes are reflected in the version? Changes are posted with each version?
    // changes are associated with a date
    // 
        
    return <Column style={{alignItems: 'center'}}>
        <h1>FAQ</h1>

        <p style={{color: theme.yellow}}>Under development...</p>

        <p>Have a question? Reach out!</p>
        <ContactButton />
    </Column>
};





export default FAQ