import React, { useEffect, useMemo, useState } from 'react';
import { Column, Row } from '../../layout/components/dom';
import { Slideshow } from '../../layout/components/image';
import { FaFacebook, FaYoutube, FaTwitter, FaReddit, FaTiktok } from 'react-icons/fa';
import { GlobalContext, useGlobal } from '../../utility/CTX';
import { ButtonPill, DownloadButton, FreeAccountButton, LinkPill, SocialButton } from '../../layout/components/button';
import {Trademark} from '../../layout/components/dom';
import { getDownloadURL, ref } from 'firebase/storage';
import { cloud } from '../../utility/firebase';
import Section from './Section';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useSession } from '../../utility/Auth';





function Home() {
    const { app, theme, dispatch } = useGlobal();
    const baseUrl = useMemo(() => 'site/assets/images');
    const session = useSession();


    useEffect(() => {
        const paths = [
            `${baseUrl}/mockup1.png`,
            `${baseUrl}/mockup2.png`,
            `${baseUrl}/mockup3.png`,
            `${baseUrl}/mockup4.png`,
            `${baseUrl}/mockup5.png`,
            `${baseUrl}/mockup6.png`,
            `${baseUrl}/mockup7.png`,
        ];
        const fetch_mockups = async (paths) => {
            try {
                const urls = await Promise.all(
                    paths.map(async (path) => {
                      const storageRef = ref(cloud, path);
                      const url = await getDownloadURL(storageRef);
                      return url;
                    })
                );
                dispatch({type: 'SET_MOCKUP_URLS', urls });
            } catch (error) {
                console.log(error);
            }
        };
        fetch_mockups(paths);
    }, [])


    

  return (
    <Column style={{justifyContent: 'center', flexGrow: 1, alignItems: 'center'}}>
        


        <Helmet>
            <title>Home - Gateway Forge</title>
            <meta name="description" content="An interactive platform empowering creators to build and develop immersive stories and worlds." />
            <meta property="og:url" content="https://gatewayforge.com/" />
            <meta property="og:title" content="Home - Gateway Forge" />
            <meta property="og:description" content="An interactive platform empowering creators to build and develop immersive stories and worlds." />

        </Helmet>

        <Slideshow imgs={app.mockupUrls}>
            <Column style={{fontWeight: 400, alignItems: 'center', maxWidth: 400, margin: 'auto', height: '100%'}}>
                <h1 style={{zIndex: 1, padding: 20, fontSize: 56, textAlign: 'center', margin: 'auto'}}>Interactive <span style={{color: theme.green}}>Worldbuilding</span> and <span style={{color: theme.orange}}>Story Development</span></h1>
                {!session && <FreeAccountButton />}
                {session && <DownloadButton />}
                <div style={{height: 10}} />
            </Column>
        </Slideshow>   


        <Row style={{color: theme.yellow, fontSize: '1.2em', margin: `20px 20px 10px 20px`}}>
            <div>Alpha Testing</div>
            <div style={{color: theme.green, marginLeft: 10}}>{`(Open)`}</div>
        </Row>

        <div style={{height: 10}} />

        <Column style={{maxWidth: 600, alignItems: 'center'}}>
            <h3 style={{maxWidth: '80%', textAlign: 'center'}}>Follow us on social media and stay up to date on new releases!</h3>
            <Row style={{fontSize: '2em', width: '80%', justifyContent: 'space-evenly'}}>
                <SocialButton url='https://www.reddit.com/r/gatewayforge/' title='Reddit'>
                    <FaReddit style={{ marginBottom: 5 }} />
                </SocialButton>
                <SocialButton url='https://www.youtube.com/@GatewayForge' title='Youtube'>
                    <FaYoutube style={{ marginBottom: 5 }} />
                </SocialButton> 
                <SocialButton url='https://www.tiktok.com/@gatewayforge' title='Tik Tok'>
                    <FaTiktok style={{ marginBottom: 5 }} />
                </SocialButton>
            </Row>
        </Column>

        <div style={{height: 30}} />

        <h2 style={{maxWidth: 800, textAlign: 'center', margin: '10px 20px'}}>An interactive platform empowering creators to build and develop immersive stories and worlds.</h2>    

        

        <div style={{height: 30}} />



        <h2 style={{maxWidth: 800, textAlign: 'center', fontSize: '2em'}}>Features</h2>    

        <Column style={{margin: '30px 50px', alignItems: 'center', overflow: 'hidden', maxWidth: 800}}>

            {/** Unlimited Entity Tracking & Cataloging */}
            <Section
                header={'Unlimited Cataloguing'}
                icon='deployed_code'
                desc={`
                    Keep your worlds limitless. Gateway Forge removes barriers by allowing you to track and 
                    catalog every detail without restrictions. Build expansive universes with confidence, 
                    knowing you’ll never run out of space to organize and expand your ideas.`}
                imgPath={`${baseUrl}/Catalog.gif`}
                color={theme.blue}
                side='left'
            />

            {/** In-Text Relational Tagging */}
            <Section
                header={'Auto-Tagging & Mentions'}
                icon='sell'
                desc={`
                    Stay effortlessly organized. Automatically link content and descriptions to the entities you’ve created, 
                    seamlessly weaving together relationships and references. Highlight a character, item, or place, 
                    and Gateway Forge keeps track for you, ensuring your stories stay consistent and connected without 
                    hours of manual effort.`}
                imgPath={`${baseUrl}/AutoTagging.gif`}
                color={theme.violet}
                side='left'
            />

            {/** Visualize Connections in 3D */}
            <Section
                header={'3D Graph of Entity Relations'}
                icon='conversion_path'
                desc={`
                    Dive deeper into your worlds. Visualize the complex web of connections between every entity in your 
                    project through an intuitive 3D graph. Explore relationships with customizable filters and controls, 
                    giving you new perspectives and insights into your stories and worlds. Perfect for spotting connections 
                    or finding gaps to enrich your narrative.`}
                imgPath={`${baseUrl}/Graph.gif`}
                color={theme.txt}
                side='right'
            />

            {/** Story Development */}
            <Section
                header={'Story Development Tools'}
                icon='history_edu'
                desc={`
                    Transform ideas into fully realized narratives. Gateway Forge’s suite of story development tools—like 
                    Beat Sheets for structuring arcs and Swimlanes for organizing plot threads—offers creative workspaces 
                    tailored for storytellers. From brainstorming to polishing your final draft, you’ll find everything you 
                    need to bring your stories to life.`}
                imgPath={`${baseUrl}/BeatSheet.gif`}
                color={theme.orange}
                side='right'
            />

            {/** Timelines */}
            <Section
                header={'Timelines & Calendars'}
                icon='timeline'
                desc={`
                    Master the flow of time. Whether your story spans days, decades, or eons, Gateway Forge gives you the 
                    tools to visualize and organize events with detailed timelines. Customize calendars to match your world’s 
                    unique timekeeping systems, ensuring every event, holiday, and epoch fits perfectly into your creative vision.`}
                imgPath={`${baseUrl}/Calendar.gif`}
                color={theme.green}
                side='right'
            />
            
            {/** Mapping */}
            <Section
                header={'Mapping'}
                icon='map'
                desc={`
                    Bring your worlds to life. Gateway Forge’s interactive maps let you plot locations, regions, and entities 
                    with precision. Mark key places, track character movements, and establish spatial relationships in ways 
                    that make your world feel tangible. Perfect for visualizing journeys, battles, or the overall layout of 
                    your universe.`}
                imgPath={`${baseUrl}/Mapping.gif`}
                color={theme.violet}
                side='right'
            />

            {/** ChatGPT Integration */}
            <Section
                header={'OpenRouter AI Integration'}
                icon='robot_2'
                desc={`
                    Collaborate with AI like never before. Gateway Forge’s seamless links your OpenRouter API key and turns AI into your 
                    personal creative assistant, offering context-aware suggestions for worldbuilding, storytelling, and brainstorming. 
                    Whether you need help developing a character or generating ideas, the AI adapts to your unique project.`}
                imgPath={`${baseUrl}/OpenRouter.gif`}
                color={theme.teal}
                side='right'
            />


            {/** Database Control */}
            <Section
                header={'Database Control'}
                icon='database'
                desc={`
                    Gateway Forge gives you unparalleled control over your project data with built-in database access. Visualize, 
                    query, and interact with your world's information using SQL directly within the platform. Whether you're organizing 
                    complex connections or analyzing intricate details, this powerful feature lets you manage your worlds with 
                    precision and flexibility.
                `}
                imgPath={`${baseUrl}/Database.gif`}
                color={theme.red}
                side='left'
            />

            
            <LowerCTA />

        </Column>

    </Column>
  )
};





const LowerCTA = () => {
    const { theme, dispatch } = useGlobal();
    const session = useSession();


    return <Column style={{marginTop: 50}}>
    
        {session && <DownloadButton />}
        {!session && <FreeAccountButton />}
    </Column>
}





export default Home