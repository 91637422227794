import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './header.scss';
import { useGlobal } from '../utility/CTX';
import { Row, Trademark } from './components/dom';
import { getDownloadURL, ref } from 'firebase/storage';
import { cloud } from '../utility/firebase';
import { useSession } from '../utility/Auth';




function handleLinkClick(e, page, dispatch, isMobile, setDropdownOpen) {
  dispatch({ type: 'SET_PAGE', page });
  if (isMobile) setDropdownOpen(false);
}


function linkStyle(currentPage, page, theme) {
  return {
    transition: 'color 0.5s ease',
    paddingLeft: 15,
    color: currentPage === page ? theme.orange : 'inherit',
    display: 'block',
    padding: '10px 15px'
  };
}


const NavOptions = ({isMobile, setDropdownOpen}) => {
  const { app, theme, dispatch } = useGlobal();
  const session = useSession();

  return <>
    <Link style={linkStyle(app.page, 'Home', theme)} to="/" onClick={(e) => handleLinkClick(e, 'Home', dispatch, isMobile, setDropdownOpen)}>
      Home
    </Link>
    
    <Link style={linkStyle(app.page, 'Pricing', theme)} to="/pricing" onClick={(e) => handleLinkClick(e, 'Pricing', dispatch, isMobile, setDropdownOpen)}>
      Pricing
    </Link>

    {session && <Link style={linkStyle(app.page, 'Download', theme)} to="/download" onClick={(e) => handleLinkClick(e, 'Download', dispatch, isMobile, setDropdownOpen)}>
      Download
    </Link>}

    <Link style={linkStyle(app.page, 'About', theme)} to="/about" onClick={(e) => handleLinkClick(e, 'About', dispatch, isMobile, setDropdownOpen)}>
      About
    </Link>

    <Link style={linkStyle(app.page, 'Contact', theme)} to="/contact" onClick={(e) => handleLinkClick(e, 'Contact', dispatch, isMobile, setDropdownOpen)}>
      Contact
    </Link>

    {!session && <Link style={linkStyle(app.page, 'Auth', theme)} to="/auth" onClick={(e) => handleLinkClick(e, 'Auth', dispatch, isMobile, setDropdownOpen)}>
      Log In
    </Link>}

    {session && <Link style={linkStyle(app.page, 'Account', theme)} to="/account" onClick={(e) => handleLinkClick(e, 'Account', dispatch, isMobile, setDropdownOpen)}>
      Account
    </Link>}
    
  </>
}







function Header() {
  const { app, theme, dispatch } = useGlobal();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [logo, setLogo] = useState('');


  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };


  return <header style={{ display: 'flex', alignItems: 'center', background: theme.bg2 }}>
    <Link
      style={{ marginRight: 25, marginLeft: 25, fontWeight: 600, fontSize: '1.5em', display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      to="/"
      onClick={(e) => dispatch({ type: 'SET_PAGE', page: 'Home' })}
    >
      <img src={'/logo512.png'} style={{width: 50, height: 50, marginRight: 15}} />
      <Trademark text="Gateway Forge" size={0.5} />
    </Link>
    {app.isMobile ? (
      <div style={{ marginRight: 25, position: 'relative' }}>
        <button onClick={toggleDropdown} style={{ fontSize: '1.5em', background: 'none', border: 'none', cursor: 'pointer', color: theme.txt }}>
          ☰
        </button>
        {dropdownOpen && (
          <div style={{ position: 'absolute', top: '100%', right: 0, background: theme.bg2, boxShadow: '0 8px 16px rgba(0,0,0,0.2)', zIndex: 5 }}>
            <NavOptions isMobile={app.isMobile} setDropdownOpen={setDropdownOpen} />
          </div>
        )}
      </div>
    ) : (
      <Row style={{ marginRight: 25 }}>
        <NavOptions isMobile={app.isMobile} setDropdownOpen={setDropdownOpen} />
      </Row>
    )}
  </header>
  

}









export default Header