import React from 'react'
import { Column } from '../../layout/components/dom'

function Blog() {


    return <Column>
    </Column>
}

export default Blog