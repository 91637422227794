import React, { useContext, useEffect, useState } from 'react';
import { signOut } from 'firebase/auth';
import { auth } from '../../utility/firebase';
import Dashboard from './Dashboard';
import General from './General';
import Security from './Security';
import Settings from './Settings';
import { useNavigate } from 'react-router-dom';
import { SessionContext, useSession } from '../../utility/Auth';
import { useGlobal } from '../../utility/CTX';
import { Column, Row } from '../../layout/components/dom';





















function AccountTab ({text, onClick, tab}) {
  const { theme } = useGlobal();

  return <div style={{margin: '0px 20px', borderBottom: '1px solid', borderColor: (tab === text) ? theme.orange : theme.txt_dark, transition: 'all 0.4s ease', color: (tab === text) ? theme.orange : theme.txt_dark, cursor: 'pointer'}} onClick={(e) => onClick(text)}>
    {text}
  </div>
}





function Account() {
  const [tab, setTab] = useState('General');
  const session = useSession();
  const { app, dispatch } = useGlobal();
  const navigate = useNavigate();

  useEffect(() => {
    if (!session) {
      navigate('/');
    }
  }, [session])





  return (
    session && <Column style={{alignItems: 'center'}}>
      <Column style={{marginTop: 20, alignItems: 'center'}}>
        <Row style={{justifyContent: 'center', alignItems: 'center', marginBottom: 20}}>
          <AccountTab text='General' onClick={setTab} tab={tab} />
          <AccountTab text='Settings' onClick={setTab} tab={tab} />
        </Row>

        {(tab === 'General') && <General />}
        {(tab === 'Settings') && <Settings />}

      </Column>
    </Column>
  )
};












export default Account