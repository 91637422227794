import React, { useContext, useEffect } from 'react'
import { SessionContext, useSession } from '../../utility/Auth';
import { signOut } from 'firebase/auth';
import { auth, db } from '../../utility/firebase';
import { getAuth, deleteUser } from 'firebase/auth';
import { deleteDoc, doc, collection, updateDoc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useGlobal } from '../../utility/CTX';
import { Column, Row } from '../../layout/components/dom';
import { useState } from 'react';
import { useMemo } from 'react';

function Settings() {
  const { theme } = useGlobal();
  const session = useSession();
  const [refresh, setRefresh] = useState(false);

  
  const navigate = useNavigate();

  const handleSignOut = () => {
    signOut(auth)
        .then(() => {
            console.log('User successfully signed out.');
            navigate('/');

        })
        .catch((err) => console.error('Error signing out the user:', err));
  };


  const deactivateUser = async () => {
    let auth = getAuth();
    let user = auth.currentUser;
    const userRef = doc(collection(db, "users"), user.uid); // Reference to the user's document
    await updateDoc(userRef, {
      status: "inactive", // Mark as inactive
    });
    console.log('user status updated.');

    setRefresh(prev => !prev);
  };



  const activateUser = async () => {
    let auth = getAuth();
    let user = auth.currentUser;
    const userRef = doc(collection(db, "users"), user.uid); // Reference to the user's document
    await updateDoc(userRef, {
      status: "active", // Mark as inactive
    });
    console.log('user status updated.');

    setRefresh(prev => !prev);
  };


  const deleteAccount = async () => {
    let auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      await deleteDoc(doc(db, "users", user.uid)); // Delete user document
      console.log("User account deleted from firestore.");


      await deleteUser(user);
      console.log("User account deleted from authentication.");

      

    }
  };


  useEffect(() => {
    console.log(session);
    
  }, [session]);




  


  const [status, setStatus] = useState(null);


  const getUserStatus = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      // Reference the user's document in the "users" collection
      const userRef = doc(collection(db, "users"), user.uid);
  
      // Fetch the document
      const userDoc = await getDoc(userRef);
  
      if (userDoc.exists()) {
        // Retrieve the "status" field
        const stat = userDoc.data().status;
        console.log(`User status: ${stat}`);
        setStatus(stat);
      } else {
        console.log("User not found.");
        setStatus(null); // User does not exist
      }
    } catch (error) {
      console.error("Error retrieving user status:", error);
      throw error; // Rethrow the error for further handling
    }
  };


  useEffect(() => {
    getUserStatus();
  }, [refresh])


  return (
    session && <Column style={{alignItems: 'center'}}>

      <h3>Settings</h3>
      
      <Column style={{margin: '20px 10px', border: '1px solid #232323', padding: 20, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', borderRadius: 10}}>
        <Btn text='Sign Out' color={theme.orange} onClick={handleSignOut} />



        <div style={{height: 50}} />


        {status !== 'inactive' && <Btn text='Deactivate' color={theme.red} onClick={deactivateUser} />}
        {status === 'inactive' && <Btn text='Activate' color={theme.red} onClick={activateUser} />}

        <Btn text='Delete' color={theme.red} onClick={deleteAccount} />
        

      </Column>



    </Column>
  )
};




const Btn = ({text, color, onClick}) => {
  const { theme } = useGlobal();
  const [hover, setHover] = useState(false);

  return <Row style={{margin: 20, cursor: 'pointer'}} onClick={onClick} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
    <div style={{color: hover ? color : theme.txt_dark}}>{text}</div>
    
  </Row>
}









export default Settings