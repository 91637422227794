import React from 'react'
import { Column, Row } from '../../layout/components/dom'
import { useGlobal } from '../../utility/CTX'
import { useState } from 'react';
import { useEffect } from 'react';
import { useMostRecentItems, useTicketTypeColors } from '../../utility/hooks';
import { IButton, ICONMAP } from '../../layout/components/button';













function Changelog() {
    const { theme } = useGlobal();
    const [changelogs, setChangelogs] = useState([]);




    const getMostRecentItems = useMostRecentItems('changelog')



    useEffect(() => {
        getMostRecentItems()
            .then((items) => {
                setChangelogs(items);
            })
            .catch((err) => {
                console.error(err);
            })
    }, []);



        
    return <Column style={{alignItems: 'center'}}>
        <h1>Change Log</h1>


        <Column>
            {changelogs.map((changelog, i) => <ChangelogColumn key={'changelog-'+changelog?.id+i} changelog={changelog} />)}
        
        </Column>
    </Column>
};










const ChangelogColumn = ({changelog}) => {
    const { theme, dispatch } = useGlobal();
    
  
  
  
    return <Column style={{flexGrow: 1, position: 'relative', minWidth: 800, maxWidth: 800, padding: 5, margin: 20}}>
    
      <Row style={{alignItems: 'center', justifyContent: 'center', fontWeight: 'bold', fontSize: '1.1em', cursor: 'pointer', padding: 10, color: theme.txt}}>
        <Row style={{alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
            <h2 style={{textWrap: 'nowrap', margin: 10}}>{changelog.title}</h2>
            <div>{changelog.createdOn?.toDate()?.toLocaleString()}</div>
        </Row>
      </Row>
  
      <div style={{margin: '5px 5px 10px 5px', borderTop: `1px solid ${theme.border}`}} />
  
  
      <Column>
        {changelog.items.map((item, i) => <ChangelogItem key={'chnglg-item-'+i} item={item} index={i} />)}
      </Column>
  
    </Column>
  };
  
  







const ChangelogItem = ({item, index}) => {
    const { theme, dispatch } = useGlobal();
    const ticketColors = useTicketTypeColors(theme);
  
  
  
  
    return <Column style={{margin: 5, color: theme.txt_dark}}>
      <Row style={{alignItems: 'start'}}>
        <Column>
          <IButton value={ICONMAP[item.type]} colored={ticketColors[item.type]} title={item.type} />
  
        </Column>
  
        <Column style={{marginLeft: 10}}>
          <b style={{fontSize: `1.2em`}}>{item.title}</b>
          <Row>{item.desc}</Row>
        </Column>
  
      </Row>
  
      <Column style={{marginLeft: 20}}>
        {item.children.map((chld, i) => <ChangelogItem key={'chld-itm-'+i + '-' +index} item={chld} index={`${index}-${i}`} />)}
      </Column>
    </Column>
  }
  

  


export default Changelog