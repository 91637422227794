import React from 'react'
import { Column } from '../../layout/components/dom'
import { useGlobal } from '../../utility/CTX';
import { Helmet } from 'react-helmet';





function PrivacyPolicy() {
    const { app, theme } = useGlobal();


    return (
      <Column style={{ alignItems: 'center', paddingBottom: 100, lineHeight: '1.6' }}>
        <Helmet>
            <title>Privacy - Gateway Forge</title>
            <meta name="description" content="Privacy policy." />

            <meta property="og:url" content="https://gatewayforge.com/privacy" />
            <meta property="og:title" content="Privacy - Gateway Forge" />
            <meta property="og:description" content="Privacy policy." />

        </Helmet>

      <Column style={{maxWidth: 700}}>
        <h1>Privacy Policy</h1>
        <p><strong>Last Updated: December 2, 2024</strong></p>
        <p>Gateway Forge ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our website (<a href='https://gatewayforge.com' style={{color: theme.orange}}>gatewayforge.com</a>) and associated services. By accessing or using our website, you consent to the practices described in this policy. If you do not agree, please discontinue use of our website.</p>
        
        <h2>1. Information We Collect</h2>
        <p>We may collect the following types of information:</p>
        <ul>
            <li><strong>Personal Information</strong>: Information you provide directly to us, such as your name, email address, or other contact details, when you contact us, sign up for an account, or otherwise interact with the website.</li>
            <li><strong>Non-Personal Information</strong>: Automatically collected data, such as your browser type, IP address, device information, and usage data (e.g., pages visited, time spent on the website). This information is collected through cookies and analytics tools.</li>
        </ul>
        
        <h2>2. How We Use Your Information</h2>
        <p>We use the information we collect for the following purposes:</p>
        <ul>
            <li>To provide, operate, and maintain the website and its functionality.</li>
            <li>To respond to your inquiries, support requests, or feedback.</li>
            <li>To improve user experience by analyzing how users interact with the website.</li>
            <li>To send updates, announcements, or notifications relevant to the website or our services.</li>
            <li>To comply with legal requirements and enforce our Terms of Service.</li>
        </ul>
        
        <h2>3. Sharing Your Information</h2>
        <p>We do not sell or rent your personal information. We may share your information in the following circumstances:</p>
        <ul>
            <li><strong>With Service Providers</strong>: We may share information with trusted third-party vendors who assist in operating our website or providing services on our behalf.</li>
            <li><strong>For Legal Reasons</strong>: We may disclose information if required to comply with applicable laws, regulations, or legal processes, or to protect our rights, property, or safety.</li>
            <li><strong>Business Transfers</strong>: If we are involved in a merger, acquisition, or sale of assets, your information may be transferred as part of that transaction.</li>
        </ul>
        
        <h2>4. Cookies and Tracking Technologies</h2>
        <p>We use cookies and similar tracking technologies to collect non-personal information about website usage and to improve functionality. Cookies are small text files stored on your device that help us analyze website traffic and user behavior. You can control or disable cookies through your browser settings, but this may affect your experience with the website.</p>
        
        <h2>5. Data Security</h2>
        <p>We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
        
        <h2>6. Your Rights and Choices</h2>
        <p>Depending on your location, you may have certain rights regarding your personal information, such as:</p>
        <ul>
            <li>Accessing the data we hold about you.</li>
            <li>Requesting corrections to inaccurate or incomplete information.</li>
            <li>Requesting deletion of your personal information, subject to legal and contractual obligations.</li>
        </ul>
        <p>To exercise these rights, please contact us using the details below.</p>
        
        <h2>7. Third-Party Links</h2>
        <p>Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these external sites. We encourage you to review the privacy policies of any third-party websites you visit.</p>
        
        <h2>8. Changes to This Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the "Last Updated" date will be revised accordingly. Your continued use of the website following changes signifies your acceptance of the updated policy.</p>
        
        <h2>9. Contact Information</h2>
        <p>If you have questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
        <p><strong>Email:</strong> <a href='mailto:contact@gatewayforge.com' style={{color: theme.orange}}>contact@gatewayforge.com</a><br />
        <strong>Website:</strong> <a href='https://gatewayforge.com' style={{color: theme.orange}}>gatewayforge.com</a></p>
      </Column>
      </Column>
    );
  }
  

export default PrivacyPolicy