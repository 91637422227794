import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaYoutube, FaTwitter, FaReddit, FaTiktok } from 'react-icons/fa';
import { useGlobal } from '../utility/CTX';
import { Column, Row, Trademark } from './components/dom';
import { cloud } from '../utility/firebase';
import { getDownloadURL, ref } from 'firebase/storage';






function Footer() {
  const { app, theme, dispatch } = useGlobal();
  const [logo, setLogo] = useState('');

  return (
    <footer style={{ display: 'flex', flexDirection: app.isMobile ? 'column' : 'row', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', background: theme.bg2, padding: 20, marginTop: 50 }}>

      
      <Row style={{ alignItems: 'center', marginBottom: 20, maxWidth: 400 }}>
        <img src={'/logo512.png'} style={{ width: 130, height: 130 }} alt="Gateway Forge Logo" />
        <Column style={{ marginLeft: 10 }}>
          <h2 style={{ color: theme.orange, margin: '5px 0px' }}><Trademark text='Gateway Forge' size={0.8} /></h2>
          <div>An interactive platform empowering creators to build and develop immersive worlds.</div>
        </Column>
      </Row>

      <div style={{display: 'flex', flexGrow: 1, maxWidth: 200}} />

      <Row style={{alignItems: 'start', justifyContent: 'space-evenly'}}>
          
        <Column style={{ marginBottom: 20, overflow: 'hidden' }}>
          <h4>Quick Links</h4>

          <Column style={{flexWrap: 'wrap'}}>
            <Link to='/' onClick={(e) => dispatch({ type: 'SET_PAGE', page: 'Home' })}>Home</Link>
            <Link to='/about' onClick={(e) => dispatch({ type: 'SET_PAGE', page: 'About' })}>About Us</Link>
            <Link to='/contact' onClick={(e) => dispatch({ type: 'SET_PAGE', page: 'Contact' })}>Contact</Link>
            <Link to='/changelog' onClick={(e) => dispatch({ type: 'SET_PAGE', page: 'Changelog' })}>Changelog</Link>
            <Link to='/faq' onClick={(e) => dispatch({ type: 'SET_PAGE', page: 'FAQ' })}>FAQ</Link>

          </Column>
          
        </Column>

        <Column style={{ marginBottom: 20, marginLeft: 50 }}>
          <h4>Legal</h4>
          <Link to='/terms' onClick={(e) => dispatch({ type: 'SET_PAGE', page: 'Terms' })}>Terms of Use</Link>
          <Link to='/privacy' onClick={(e) => dispatch({ type: 'SET_PAGE', page: 'Privacy' })}>Privacy Policy</Link>
        </Column>

        <Column style={{ fontSize: '1.5em', marginBottom: 20, marginLeft: 30, marginTop: 30 }}>
          <SocialButton url='https://www.reddit.com/r/gatewayforge/'>
            <FaReddit style={{ marginBottom: 5 }} />
          </SocialButton>
          <SocialButton url='https://www.youtube.com/@GatewayForge'>
            <FaYoutube style={{ marginBottom: 5 }} />
          </SocialButton> 
          <SocialButton url='https://www.tiktok.com/@gatewayforge'>
            <FaTiktok style={{ marginBottom: 5 }} />
          </SocialButton>
        </Column>
      </Row>

      
    </footer>
  );
}

const SocialButton = ({ url, children }) => {
  return <a href={url} target='_blank' rel='noopener noreferrer'>{children}</a>;
}

export default Footer;
