import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ContactButton, DownloadButton, FreeAccountButton, LinkPill } from '../../layout/components/button';
import { useGlobal } from '../../utility/CTX';
import { Column, Row } from '../../layout/components/dom';
import { getDownloadURL, ref } from 'firebase/storage';
import { cloud } from '../../utility/firebase';
import { Helmet } from 'react-helmet';
import { useSession } from '../../utility/Auth';

function About() {
  const { app, theme } = useGlobal();
  const session = useSession();

  



  return <Column style={{alignItems: 'center'}}>
    <Helmet>
      <title>About - Gateway Forge</title>
      <meta name="description" content="Get to know the team behind the project." />

      <meta property="og:url" content="https://gatewayforge.com/about" />
      <meta property="og:title" content="About - Gateway Forge" />
      <meta property="og:description" content="Get to know the team behind the project." />

    </Helmet>

    <h1 style={{padding: 20}}>About Us</h1>
    <Column style={{maxWidth: 800, margin: '0px 40px 40px 40px'}}>
      <p>
          Gateway Forge is the ultimate toolkit for creators who build worlds, develop stories, 
          and map intricate ideas. Launched in 2024, the platform is designed with a clear purpose: 
          to give storytellers, worldbuilders, and creative minds a space where their visions thrive.
      </p>
      <p>
          At its core, the platform focuses on cataloging, creating, and visualizing. 
          Whether you construct a sprawling fantasy realm, weave together complex narratives, 
          or chart out the connections between your ideas, this toolkit provides the features 
          needed to bring it all into focus. It is purpose-built for the unique challenges of organizing 
          and expanding creative worlds, going beyond what general note-taking apps can offer.
      </p>
      <p>
          Behind this innovative platform is a small team of creators who share a deep passion 
          for the art of storytelling and worldbuilding. Writers, game developers, and storytellers have 
          collaborated to craft software that is both intuitive and powerful, designed to inspire creativity 
          and adapt to the needs of its users.
      </p>
      <p>
          As the platform continues to evolve, it grows alongside its community of creators. Whether you are 
          sketching the seeds of an idea or shaping the intricate details of a larger project, this platform 
          supports your imagination every step of the way. 
      </p>
      <p>
          If you would like to get in touch or provide feedback, you may reach out to:<br/>
          <a href="mailto:contact@gatewayforge.com" style={{color: theme.orange}}>contact@gatewayforge.com</a>
      </p>


      <div>
        <div>Sincerely,</div>
        <div>The Gateway Forge Team</div>
      </div>
    
    </Column>


    <Row  style={{margin: app.isMobile ? '50px 10px 0px 10px' : '50px 250px 0px 250px', borderTop: '1px solid', borderColor: theme.border, padding: 20, justifyContent: 'center'}}>
      {!session && <FreeAccountButton />}
      {session && <DownloadButton />}

      <div style={{width: 20}} />

      <ContactButton />
    </Row>
  </Column>

};




const WaitlistButton = () => {
  const { theme, dispatch } = useGlobal();
  const [hover, setHover] = useState(false);


  return <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <LinkPill to='/waitlist' text='Join the Waitlist' onClick={() => dispatch({ type: 'SET_PAGE', page: 'Waitlist'})} 
          style={{transition: 'background 0.2s ease, color 0.2s ease', border: `1px solid ${theme.blue}`, color: hover ? theme.bg2 : theme.blue, background: hover && theme.blue, borderRadius: 50, padding: '0px 5px 0px 15px'}} />
  </div>
}



const GetInTouchButton = () => {
  const { theme, dispatch } = useGlobal();
  const [hover, setHover] = useState(false);


  return <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <LinkPill to='/contact' text='Get In Touch' onClick={() => dispatch({ type: 'SET_PAGE', page: 'Contact'})} 
          style={{transition: 'background 0.2s ease, color 0.2s ease', border: `1px solid ${theme.orange}`, color: hover ? theme.bg2 : theme.orange, background: hover && theme.orange, borderRadius: 50, padding: '0px 5px 0px 15px'}} />
  </div>
}






export default About