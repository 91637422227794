import React from 'react'
import { Column, Row } from '../../layout/components/dom'
import { useGlobal } from '../../utility/CTX';
import { ButtonPill, DownloadButton, FreeAccountButton } from '../../layout/components/button';
import { useSession } from '../../utility/Auth';
import { Helmet } from 'react-helmet';





function Pricing() {
    const { theme } = useGlobal();


    return <Column style={{flexGrow: 1, alignItems: 'center'}}>

        <Helmet>
            <title>Pricing - Gateway Forge</title>
            <meta name="description" content="All features available free and offline, unlimited storage." />

            <meta property="og:url" content="https://gatewayforge.com/pricing" />
            <meta property="og:title" content="Pricing - Gateway Forge" />
            <meta property="og:description" content="All features available free and offline, unlimited storage." />

        </Helmet>

        <PricingHeader />

        <Row style={{color: theme.yellow, fontSize: '1.2em', margin: `20px 20px 10px 20px`}}>
            <div>Alpha Testing</div>
            <div style={{color: theme.green, marginLeft: 10}}>{`(Open)`}</div>
        </Row>

        <Row style={{flexGrow: 1, justifyContent: 'center'}}>
            <TierOption>
                <FreeTier />
            </TierOption>
        </Row>
    </Column>
};






const PricingHeader = () => {

    return <Column style={{alignItems: 'center'}}>
        <h1 style={{padding: 20}}>Pricing</h1>
    </Column>
};






const TierOption = ({children}) => {
    const { theme } = useGlobal();

    return <Column style={{alignItems: 'center', background: theme.bg, margin: 20, borderRadius: 10, border: `1px solid ${theme.border}`, width: 200, maxWidth: 200, minWidth: 200, overflow: 'hidden'}}>
        {children}
    </Column>
}

const FreeTier = () => {
    const { theme } = useGlobal();
    const session = useSession();

    return <Column style={{alignItems: 'center', width: '100%'}}>
        <Column style={{marginBottom: 10, alignItems: 'center', width: '100%'}}>
            <h3 style={{width: '100%', background: theme.bg2, margin: 0, textAlign: 'center'}}>Basic</h3>
            <h2>FREE</h2>
            <p style={{textAlign: 'center', padding: 10}}>Access to all offline features.</p>
        </Column>

        {session && <DownloadButton />}
        {!session && <FreeAccountButton />}

        <div style={{height: 20}} />
    </Column>
}


const PaidTier = () => {


    return <Column>
        <Column style={{minHeight: 250, height: 250, maxHeight: 250}}>
            <h4>Premium</h4>
            <h2>$9.99</h2>
            <p>Sync your projects to the cloud. Collaborate in real-time with others.</p>
        </Column>

    </Column>
}







export default Pricing