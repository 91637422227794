import React from 'react';
import { Link } from 'react-router-dom';
import { Row } from './dom';
import { useGlobal } from '../../utility/CTX';
import { useState } from 'react';
import { useMemo } from 'react';
import { isDesktop } from '../../utility/assist';


export function LinkPill ({to, text, onClick, style}) {
    return (
        <Link to={to} onClick={onClick} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', ...style}}>
            <div>{text}</div>
            <span className='material-symbols-rounded'>chevron_right</span>
        </Link>
    )
}



export function HrefPill ({href, text, onClick, style, newTab}) {
    return (
        <a href={href} onClick={onClick} style={style} target={newTab && '_blank'}>
            <div>{text}</div>
            <span className='material-symbols-rounded'>chevron_right</span>
        </a>
    )
}



export function ButtonPill ({text, onClick, style}) {
    const { theme } = useGlobal();

    return <Row onClick={onClick} style={{alignItems: 'center', justifyContent: 'center', borderRadius: 25, cursor: 'pointer', ...style}}>
            <div style={{marginRight: 5}}>{text}</div>
            <span className='material-symbols-rounded'>chevron_right</span>
        </Row>
    
}






export const Button = ({text, page, endpoint, icon, color}) => {
    const { theme, dispatch } = useGlobal();
    const [ hover, setHover ] = useState(false);

    return <div style={{margin: 'auto 15px', background: hover ? theme.bg4 : theme.bg2, padding: '5px 10px 5px 15px', border: `1px solid ${hover ? (color || theme.orange) : theme.border}`, borderRadius: 50, width: 'auto', cursor: 'pointer'}} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        <Link style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            textWrap: 'nowrap',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 5,
            borderRadius: 5,
            textAlign: 'center',
            color: color || theme.orange,
            fontWeight: 400,
            cursor: 'pointer'
        }} to={endpoint} onClick={(e) => dispatch({ type: 'SET_PAGE', page})}>{text} <span className='material-symbols-rounded'>{icon}</span></Link>
    </div>
};





export const IButton = ({value, label, onClick, style, side='right', title, colored, onColor}) => {

    return <div style={{ cursor: 'pointer', color: colored, ...style }} title={title}>
        {side === 'left' && label && <span style={{marginRight: 5}}>{label}</span>} 
        <span className='material-symbols-rounded' style={{fontWeight: 200}}>{value}</span>
        {side === 'right' && label && <span style={{marginLeft: 5}}>{label}</span>}
    </div>
};





export const ICONMAP = {
    story: 'history_edu',
    feature: 'featured_seasonal_and_gifts',
    epic: 'globe',
    bug: 'bug_report',
    task: 'task',
    improvement: 'update',
    hotfix: 'mode_heat',
    feedback: 'feedback',
    sprint: 'sprint'
}








export const DownloadButton = () => {
    const { theme } = useGlobal();

    let desktop = useMemo(() => {
        return isDesktop();
    }, []);

    return desktop
        ? <Button
            text={'Download'}
            page='Download'
            endpoint='/download'
            icon='chevron_right'
        />
        : <Row style={{color: theme.red}}>
            Desktop Only
        </Row>
};





export const FreeAccountButton = () => {
    return <Button
        text='Free Account'
        page='Auth'
        endpoint='/auth'
        icon='chevron_right'
    />
};




export const ContactButton = () => {
    const { theme } = useGlobal();
    
    return <Button
        text='Contact'
        page='Contact'
        endpoint='/contact'
        icon='chevron_right'
        color={theme.blue}
    />
};




export const SocialButton = ({ url, children, title }) => {
  return <a href={url} target='_blank' rel='noopener noreferrer' title={title}>{children}</a>;
}







