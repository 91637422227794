import React, { useState, createContext, useContext, useEffect } from 'react';
import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, sendPasswordResetEmail, getAuth, signOut } from 'firebase/auth';

import { auth, db, welcomeFunction } from './firebase.js';
import {doc, getDoc} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { initializeSession } from './External.js';
import { Helmet } from 'react-helmet';
import { useGlobal } from './CTX.js';
import { Row } from '../layout/components/dom.js';








async function Authenticate (email, password, setSession) {
    try {
        const credentials = await signInWithEmailAndPassword(auth, email, password);
        return credentials;
    } catch (error) {
        console.error('Error authenticating account:', error);
        return null;
    }
}


async function Register (email, password, setSession) {
    try {
        const credentials = await createUserWithEmailAndPassword(auth, email, password);
        welcomeFunction({
            email
        }).then((res) => {
            console.log(res);
        }).catch((err) => {
            console.error(err);
        });
        return credentials;

    } catch (error) {
        console.error('Error creating account:', error);
        return null;
    }
}

async function SignOut(setSession) {
    try {
        await signOut(auth);
        console.log("User signed out successfully.");
        if (setSession) {
            setSession(null); // Clear the session if needed
        }
        return true;
    } catch (error) {
        console.error("Error signing out:", error);
        return false;
    }
}





export const useAuthSession = () => {
    const [session, setSession] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const session = await initializeSession(user);
                setSession(session);
            } else {
                // RESET TO NULL
                setSession(null);
            }
        });

        return () => unsubscribe();
    }, []);

    return session;
}


export const SessionContext = createContext(null);
export const useSession = () => useContext(SessionContext);











function Auth() {
    const { theme } = useGlobal();
    const [option, setOption] = useState('SignUp');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const session = useSession();
    const navigate = useNavigate();
    




  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: 500, fontSize: '1.2em'}}>
        <Helmet>
            <title>Authenticate - Gateway Forge</title>
            <meta name="description" content="Register for a free account or log in to an existing account to unlock unlimited access to Gateway Forge." />
            <meta property="og:url" content="https://gatewayforge.com/" />
            <meta property="og:title" content="Authenticate - Gateway Forge" />
            <meta property="og:description" content="Register for a free account or log in to an existing account to unlock unlimited access to Gateway Forge." />

        </Helmet>

        <div style={{padding: '20px 0px', background: theme.bg, display: 'flex', flexDirection: 'column', alignItems: 'center', border: '1px solid #232323', borderRadius: 10, width: 300}}>
            <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-evenly', marginBottom: 10}}>
                <div className='transition' style={{cursor: 'pointer', padding: '2px 10px', background: option==='SignIn' && theme.bg3, color: option==='SignIn' ? theme.orange : theme.txt_dark, borderRadius: 5}} onClick={(e) => setOption('SignIn')}>Sign In</div>
                <div className='transition' style={{cursor: 'pointer', padding: '2px 10px', background: option==='SignUp' && theme.bg3, color: option==='SignUp' ? theme.blue : theme.txt_dark, borderRadius: 5}} onClick={(e) => setOption('SignUp')}>Sign Up</div>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', margin: 20}}>
                    <Row style={{justifyContent: 'end', width: '80%'}}>
                        {option === 'SignIn' && <i style={{color: theme.orange}}>Forgot</i>}
                    </Row>
                    <input placeholder='Email' style={{outline: 'none', width: '80%', padding: '2px 5px', fontSize: '1.1em', borderRadius: 5, fontFamily: 'Rajdhani'}} type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', margin: 20}}>
                    <Row style={{justifyContent: 'end', width: '80%'}}>
                        {option === 'SignIn' && <i style={{color: theme.orange}}>Forgot</i>}
                    </Row>

                    <input placeholder='Password' style={{outline: 'none', width: '80%', padding: '2px 5px', fontSize: '1.1em', borderRadius: 5, fontFamily: 'Rajdhani'}} type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>


            </div>
            
            <div>
                <div style={{cursor: 'pointer', padding: '2px 10px', borderRadius: 50, background: theme.bg3, color: option === 'SignUp' ? theme.blue : theme.orange, border: `1px solid ${theme.border}`}} onClick={async (e) => {
                    if (option === 'SignUp') {
                        // create a new account
                        const success = await Register(email, password);
                        if (success)    navigate('/');
                    } else {
                        // authenticate an existing account
                        const success = await Authenticate(email, password);
                        console.log(success);
                        if (success)    navigate('/');
                    }
                    
                }}>
                    Submit
                </div>
            </div>
        </div>



    </div>
  )
}







const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    const auth = getAuth();

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Password reset email sent! Check your inbox.");
      setError(""); // Clear any previous error
    } catch (err) {
      setError(err.message);
      setMessage(""); // Clear any previous success message
    }
  };

  return (
    <div>
      <h2>Forgot Password</h2>
      <form onSubmit={handleForgotPassword}>
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit">Send Reset Email</button>
      </form>
      {message && <p style={{ color: "green" }}>{message}</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};








export default Auth