import React, { useEffect, useState } from 'react'
import { Column, Row } from './dom';
import { useGlobal } from '../../utility/CTX';




export function Slideshow({ imgs = [], children, style, ...props }) {
    const { theme } = useGlobal();
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex(prev => (prev + 1) % imgs.length);
        }, 5000);
        
        return () => {
            clearInterval(interval);
        }
    }, [imgs]);


    


    return <Row style={{position: 'relative', maxWidth: '100%', flexWrap: 'wrap',  ...style}} {...props}>
        
                
        {children}

        <Column style={{overflow: 'hidden', borderRadius: 5, paddingTop: 20, margin: 'auto'}}>
            <img src={imgs[index]} style={{
                flexGrow: 1,
                width: '90%',
                maxWidth: 600,
                height: 'auto',
                margin: 'auto',
                borderRadius: 5
            }} />
        </Column>

    </Row>
};












export const Image = ({imgUrl, height}) => {
    return <div style={{
        backgroundImage: `url(${imgUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }}></div>
}