import { useCallback } from "react";
import { getDownloadURL, ref } from 'firebase/storage';
import { cloud } from './firebase';
import { useEffect } from "react";











export const useImageLoader = (callback=()=>{}, imgPath) => useEffect(() => {
    const getImage = async () => {
        if (!imgPath)   return;

        const storageRef = ref(cloud, imgPath);
        const url = await getDownloadURL(storageRef);
        callback(url);
    };

    getImage();
}, [callback, imgPath]);





export const isDesktop = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
    // Check for mobile devices
    const isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent.toLowerCase());
  
    return !isMobile; // Return true for desktop, false for mobile
};

  




