import React from 'react'
import { Column } from '../../layout/components/dom'
import { useGlobal } from '../../utility/CTX';
import { Helmet } from 'react-helmet';



function TermsAndConditions() {
    const { app, theme } = useGlobal();


    return (
      <Column style={{ alignItems: 'center', paddingBottom: 100, lineHeight: '1.6' }}>
        <Helmet>
            <title>Terms - Gateway Forge</title>
            <meta name="description" content="Terms and conditions." />

            <meta property="og:url" content="https://gatewayforge.com/terms" />
            <meta property="og:title" content="Terms - Gateway Forge" />
            <meta property="og:description" content="Terms and conditions." />

        </Helmet>

        <Column style={{maxWidth: 700, margin: 'auto'}}>
        
        
          <h2>Terms of Service</h2>
          <p>Last Updated: December 2, 2024</p>

          <h3>1. Introduction</h3>
          <p>Welcome to Gateway Forge (<a href='https://gatewayforge.com' style={{color: theme.orange}}>gatewayforge.com</a>). These Terms of Service govern your use of our website and any services provided through it. By accessing or using the website, you agree to comply with these Terms. If you do not agree, please discontinue use of the website.</p>

          <h3>2. Use of the Website</h3>
          <p>You agree to use the website only for lawful purposes and in a manner consistent with these Terms. You must not attempt to gain unauthorized access to any portion of the website or its systems, upload or transmit viruses, malware, or any other malicious code, interfere with or disrupt the functionality or performance of the website, or use the website in a way that could harm its reputation or functionality. We reserve the right to restrict or terminate access to the website for users who violate these Terms.</p>

          <h3>3. Intellectual Property</h3>
          <p>All content on this website, including but not limited to text, graphics, logos, images, and software, is the property of Gateway Forge and is protected by intellectual property laws. You may not reproduce, distribute, or create derivative works from this content without prior written permission.</p>

          <h3>4. Third-Party Links</h3>
          <p>The website may include links to third-party websites for informational or convenience purposes. We do not endorse or control the content, policies, or practices of these websites. Accessing third-party websites is at your own risk, and we are not responsible for their content or actions.</p>

          <h3>5. Disclaimer of Warranties</h3>
          <p>The website is provided "as is" and "as available" without warranties of any kind, whether express or implied. Gateway Forge does not guarantee that the website will be uninterrupted, secure, or error-free, the accuracy or reliability of any content or information on the website, or that any issues with the website will be corrected.</p>

          <h3>6. Limitation of Liability</h3>
          <p>To the fullest extent permitted by law, Gateway Forge will not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising from your use of or inability to use the website, unauthorized access to or alteration of your transmissions or data, or any other matter related to the website. This limitation applies even if Gateway Forge has been advised of the possibility of such damages.</p>

          <h3>7. User Contributions</h3>
          <p>If you submit any comments, suggestions, or other materials to the website, you grant Gateway Forge a non-exclusive, royalty-free, perpetual, and irrevocable right to use, reproduce, modify, and display such contributions.</p>

          <h3>8. Changes to Terms</h3>
          <p>We reserve the right to modify these Terms at any time. Changes will be effective immediately upon posting on this page. By continuing to use the website after changes are made, you agree to the updated Terms.</p>

          <h3>9. Governing Law</h3>
          <p>These Terms of Service are governed by and construed in accordance with the laws of Colorado, without regard to its conflict of law principles. Any disputes arising from these Terms or your use of the website shall be subject to the exclusive jurisdiction of the courts in Colorado.</p>

          <p>If you have questions or concerns about these Terms of Service, please contact us at:<br />
          Email: <a href='mailto:contact@gatewayforge.com' style={{color: theme.orange}}>contact@gatewayforge.com</a></p>
        </Column>
      </Column>
    );
  }



export default TermsAndConditions