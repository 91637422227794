import React, { useContext, useState, useEffect } from 'react';
import { EmailInput, TextAreaInput, TextInput } from '../../layout/components/input';
import { Link } from 'react-router-dom';
import { GlobalContext, useGlobal } from '../../utility/CTX';
import {httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import { cloud, contactFunction } from '../../utility/firebase';
import { getDownloadURL, ref } from 'firebase/storage';
import { Column } from '../../layout/components/dom';
import { Helmet } from 'react-helmet';
import { useSession } from '../../utility/Auth';








function Contact() {
  const { app, theme, dispatch } = useGlobal();

  const {ctx, setCtx} = useContext(GlobalContext);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const session = useSession();

  //connectFunctionsEmulator(functions, "127.0.0.1", 5001); // Replace "127.0.0.1" with "localhost" if needed


  const sendEmail = () => {
    contactFunction({
      email,
      subject,
      message: `Name: ${name}\n${message}`
    }).then((res) => {
      if (res.data.success) {
        console.log('Email sent successfully');
      } else {
        console.log('Error sending email: ', res);
      }
    }).catch((err) => {
      console.log('Error calling contact function: ', err.message);
    })
  };



  return <Column style={{alignItems: 'center'}}>
    <Helmet>
      <title>Contact - Gateway Forge</title>
      <meta name="description" content="Get in touch, if there is anything you would like to see included in future updates, or if there is anything you think needs to be fixed feel free to reach out!" />

      <meta property="og:url" content="https://gatewayforge.com/contact" />
      <meta property="og:title" content="Contact - Gateway Forge" />
      <meta property="og:description" content="Get in touch, if there is anything you would like to see included in future updates, or if there is anything you think needs to be fixed feel free to reach out!" />

    </Helmet>


        
    <Column style={{display: 'flex', alignItems: 'center', padding: 20,  zIndex: 1}}>
      <h1>Contact Us</h1>
      <h2>We'd love to hear from you!</h2>
      <p>Have a question, idea, or just want to say hello? Reach out to us using the form below, and we'll get back to you as soon as possible.</p>
    </Column>

    <div style={{display: 'flex', flexDirection: 'row', maxWidth: 800, margin: '0px 20px', zIndex: 1, flexWrap: 'wrap', justifyContent: 'center'}}>
      <div style={{display: 'flex', flexGrow: 1, flexDirection: 'column', borderRight: '1px solid', borderColor: theme.border}}>
        <TextInput label='Name' value={name} setValue={setName} style={{
          borderRadius: 5,
          padding: '2px 5px',
          fontSize: '0.9em',
          width: '100%'
        }}/>
        <EmailInput value={email} setValue={setEmail} style={{
          borderRadius: 5,
          padding: '2px 5px',
          fontSize: '0.9em',
          width: '100%'
        }}/>
        <TextInput label='Subject' value={subject} setValue={setSubject} style={{
          borderRadius: 5,
          padding: '2px 5px',
          fontSize: '0.9em',
          width: '100%'
        }}/>
        <TextAreaInput label='Message' value={message} setValue={setMessage} rows={6} style={{
          width: '100%',
          borderRadius: 5,
          padding: '5px',
          fontSize: '1em'
        }} />

        <SubmitButton sendEmail={sendEmail} />
      </div>

      <div style={{display: 'flex', flexGrow: 1, flexDirection: 'column', maxWidth: '50%', justifyContent: 'space-evenly', zIndex: 1, padding: '20px 0px'}}>
      
        {!session && <ActionLink
          text="Create A Free Account"
          to="/auth"
          pageType="Auth"
          description="Create your free account to start exploring the platform!"
          theme={theme}
          dispatch={dispatch}
        />}

        {session && <ActionLink
          text="Download"
          to="/download"
          pageType="Download"
          description="Download Gateway Forge and start creating offline today!"
          theme={theme}
          dispatch={dispatch}
        />}

        <ActionLink
          text="About Us"
          to="/about"
          pageType="About"
          description="Learn more about our company and who we are."
          theme={theme}
          dispatch={dispatch}
        />


      </div>
    </div>
    
    <div style={{height: 100}}>

    </div>
  </Column>
};





const ActionLink = ({ text, to, pageType, description, theme, dispatch }) => {
  return (
    <div style={{ margin: '0px 15px' }}>
      <i>{description}</i>
      <Link
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          textWrap: 'nowrap',
          padding: 5,
          borderRadius: 5,
          marginLeft: '70%',
          textAlign: 'center',
          alignItems: 'center',
          color: theme.orange,
          fontWeight: 400,
          cursor: 'pointer',
        }}
        to={to}
        onClick={(e) => dispatch({ type: 'SET_PAGE', page: pageType })}
      >
        {text} <span className="material-symbols-rounded">chevron_right</span>
      </Link>
    </div>
  );
};











const SubmitButton = ({sendEmail}) => {
  const { theme } = useGlobal();
  const [hover, setHover] = useState(false);

  return <div style={{
    padding: 5,
    borderRadius: 15,
    background: theme.bg2,
    border: `1px solid ${hover ? theme.orange : theme.border}`,
    width: 75,
    margin: '20px auto',
    textAlign: 'center',
    color: theme.orange,
    fontWeight: 500,
    cursor: 'pointer'
  }}
  onMouseEnter={() => setHover(true)}
  onMouseLeave={() => setHover(false)}
  onClick={sendEmail}>Submit</div>
}

export default Contact