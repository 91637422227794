import { doc, getDoc, setDoc } from "firebase/firestore"
import { db } from "./firebase"




export const initializeSession = async (user) => {
    const session = { user };
    const data = await fetchUserData(user);
    if (data) {
        session.user.data = data;
    }
    return session;
}





export const fetchUserData = async (user) => {
    if (user) {
        const docRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const data = docSnap.data();
            if (data) user.displayName = data.displayName;
            return data;
        } else {
            // Document doesn't exist, so create a new entry
            const defaultData = {
                userId: user.uid,
                displayName: user.displayName || "Anonymous",
                email: user.email,
                createdAt: new Date().toISOString(),
                subscriptionStatus: "none"
            };
            
            await setDoc(docRef, defaultData);
            console.log("New user document created.");
            return defaultData;
        }
    }

    return null;
}