import React from 'react'
import { Column, Row } from '../../layout/components/dom'
import { useGlobal } from '../../utility/CTX'
import { useImageLoader } from '../../utility/assist';
import { useState } from 'react';

function Section({header, desc, imgPath, icon, color, side='left'}) {
    const { app, theme } = useGlobal();
    const [imgUrl, setImgUrl] = useState(null);



    useImageLoader(setImgUrl, imgPath);


        
    return <Row style={{flexGrow: 1, background: theme.bg2, borderRadius: 5, margin: '10px 0px', border: `1px solid ${theme.border}`, maxWidth: '90%', flexWrap: app.isMobile && 'wrap', overflow: 'hidden'}}>
        
        <img src={imgUrl} style={{flexGrow: 1, pointerEvents: 'none', maxHeight: 350, height: 'auto', width: 'auto', flexShrink: 1, aspectRatio: 1, objectFit: 'cover'}} />
        
        <Column style={{flexGrow: 1, margin: '20px 30px'}}>
            <Row style={{alignItems: 'center'}}>
                <span className='material-symbols-rounded' style={{fontSize: '2em', marginRight: 10, color}}>{icon}</span>
                <h2 style={{margin: '10px 0px'}}>{header}</h2>
            </Row>
            <div>{desc}</div>
        </Column>

      
    </Row>
}

export default Section