
import './app.scss';
import './layout/styles.scss';

import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Home from './pages/home/Home';
import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import NoPage from './pages/nopage/NoPage';
import Header from './layout/Header';
import Footer from './layout/Footer';
import { useState } from 'react';

import Auth, { SessionContext, useAuthSession } from './utility/Auth';
import { GlobalContext, GlobalContextProvider, useGlobal } from './utility/CTX';
import { initialState, reducers } from './utility/reduce';
import PrivacyPolicy from './pages/privacy/PrivacyPolicy';
import TermsAndConditions from './pages/privacy/TermsAndConditions';
import Account from './pages/account/Account';
import Blog from './pages/blog/Blog';
import Pricing from './pages/pricing/Pricing';
import Download from './pages/download/Download';
import Changelog from './pages/changelog/Changelog';
import FAQ from './pages/faq/FAQ';








function App() {
  const session = useAuthSession();




  return (
    <GlobalContextProvider initialState={initialState} reducers={reducers}>
      <SessionContext.Provider value={session}>
        <BrowserRouter>

          <Header />

          <Routes>
            <Route path="/"             element={<Home />} />
            <Route path="/account"      element={<Account />} />
            <Route path="/pricing"      element={<Pricing />} />
            <Route path="/download"      element={<Download />} />
            <Route path="/changelog"      element={<Changelog />} />
            <Route path="/faq"          element={<FAQ />} />


            <Route path="/blog"         element={<Blog />} />
            <Route path="/about"        element={<About />} />
            <Route path="/contact"      element={<Contact />} />
            <Route path="/auth"         element={<Auth />} />

            <Route path="/privacy"      element={<PrivacyPolicy />} />
            <Route path="/terms"        element={<TermsAndConditions />} />
            <Route path="/nopage"       element={<NoPage />} />

          </Routes>

          <Footer />

        </BrowserRouter>
      </SessionContext.Provider>
    </GlobalContextProvider>
  );
}

export default App;
