import { useCallback } from "react";
import { query, collection, limit, orderBy, getDocs } from 'firebase/firestore';
import { db } from './firebase';
import { useMemo } from "react";






export const useMostRecentItems = (collectionName, lim=10) => useCallback(async () => {
    try {
        // Reference the collection and create a query
        const recentItemsQuery = query(
          collection(db, collectionName),
          orderBy("createdOn", "desc"), // Sort by createdOn descending
          limit(lim) // Limit to 10 items
        );
    
        // Execute the query
        const querySnapshot = await getDocs(recentItemsQuery);
    
        // Map and return the data from the documents
        return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      } catch (error) {
        console.error("Error fetching most recent items:", error);
        throw error;
      }
}, [collectionName, lim]);







export const useTicketTypeColors = (theme) => useMemo(() => {
    return {
        epic: theme.violet,
        story: theme.orange,
        bug: theme.teal,
        improvement: theme.pink,
        feature: theme.blue,
        task: theme.yellow,
        hotfix: theme.red,
        backlog: theme.txt,
        note: theme.txt,
        feedback: theme.green
    };
}, [theme]);







