

import React from 'react'
import { Column, Row } from '../../layout/components/dom'
import { useSession } from '../../utility/Auth'
import { useGlobal } from '../../utility/CTX';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ContactButton } from '../../layout/components/button';
import { isDesktop } from '../../utility/assist';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../utility/firebase';
import { getAuth }  from 'firebase/auth';









function Download() {
        const session = useSession();
        const { theme } = useGlobal();
        const [version, setVersion] = useState({}); // Replace with your app's current version




        const desktop = useMemo(() => {
            return isDesktop();
        }, []);

     

        useEffect(() => {

            fetch('/version.json')
                .then(async (res) => {
                    let data = await res.json()
                    setVersion(data)
                })
                .catch((err) => {});
        }, []);





            
        return <Column style={{alignItems: 'center'}}>
            <Row style={{color: theme.yellow, fontSize: '1.2em', margin: `20px 20px 10px 20px`}}>
                <div>Alpha Testing</div>
                <div style={{color: theme.green, marginLeft: 10}}>{`(Open)`}</div>
            </Row>
            
            {!session && <Column style={{margin: 50}}>
                <AccountButton />
            </Column>}
            

            {session && <Column>
                <h1>Current Version</h1>
                <Row style={{marginBottom: 10, fontSize: '1.2em', justifyContent: 'end'}}>{version?.version}</Row>
                <Row style={{justifyContent: 'space-between', margin: 20}}>
                    <div>Mac ARM</div>

                    {desktop && <Row>
                        <DownloadLink version='mac-arm' text='.zip' linkTo={version?.mac?.arm || ''} marginRight={20} />
                    </Row>}
                    {!desktop && <Row style={{color: theme.red}}>
                        Desktop Only
                    </Row>}
                </Row>
                <Row style={{justifyContent: 'space-between', margin: 20}}>
                    <div>Mac Intel</div>

                    {desktop && <Row>
                        <DownloadLink version='mac' text='.zip' linkTo={version?.mac?.intel || ''} marginRight={20} />
                    </Row>}
                    {!desktop && <Row style={{color: theme.red}}>
                        Desktop Only
                    </Row>}
                </Row>
                <Row style={{justifyContent: 'space-between', margin: 20}}>
                    <div>Windows</div>

                    {desktop && <div>
                        <DownloadLink version='win' text='.zip' linkTo={version?.win?.x64 || ''} marginRight={20} />
                    </div>}
                    {!desktop && <Row style={{color: theme.red}}>
                        Desktop Only
                    </Row>}
                </Row>

                <Column style={{maxWidth: 400, margin: 20}}>
                    <b style={{color: theme.red, marginRight: 5}}>MAC USERS:</b>
                    <div>You may need to go through <b>System Settings</b> {'>'} <b>Privacy & Security</b> to manually open the software.</div>
                </Column>

                <Column style={{marginTop: 50}}>
                    <p>For older versions, please reach out and inquire:</p>
                    <ContactButton />
                </Column>
            </Column>}
        
        </Column>
};






const DownloadLink = ({version, text, linkTo, marginRight}) => {
    const { theme } = useGlobal();
    
    const [hover, setHover] = useState(false);



    const handleClick = useCallback((e) => {
        let auth = getAuth();
        let user = auth.currentUser;
        logEvent(analytics, 'download', {
            userId: user?.uid,
            version
        })
    }, [version]);



    return <a download
    style={{
            marginRight, 
            color: theme.orange, 
            cursor: 'pointer', 
            padding: '2px 15px', 
            borderRadius: 40, 
            border: `1px solid ${hover ? theme.orange : theme.border}`, 
            background: hover ? theme.bg3 : theme.bg2
    }} href={linkTo}
    onClick={handleClick}
    onMouseEnter={() => setHover(true)}
    onMouseLeave={() => setHover(false)}>
        {text}
    </a>
}





const AccountButton = () => {
    const { theme, dispatch } = useGlobal();
    const [ hover, setHover ] = useState(false);

    return <div style={{margin: 'auto 15px', background: hover ? theme.bg4 : theme.bg2, padding: '5px 10px 5px 15px', border: `1px solid ${hover ? theme.orange : theme.border}`, borderRadius: 50, width: 'auto', cursor: 'pointer'}} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        <Link style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            textWrap: 'nowrap',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 5,
            borderRadius: 5,
            textAlign: 'center',
            color: theme.orange,
            fontWeight: 400,
            cursor: 'pointer'
        }} to='/auth' onClick={(e) => dispatch({ type: 'SET_PAGE', page: 'Auth'})}>Free Account <span className='material-symbols-rounded'>chevron_right</span></Link>
    </div>
}




export default Download