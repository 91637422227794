import React, { useContext, useEffect } from 'react'
import {  useSession } from '../../utility/Auth'
import { useGlobal } from '../../utility/CTX';
import { Column, Row } from '../../layout/components/dom';
import { doc, setDoc } from "firebase/firestore";
import { EmailAuthProvider, reauthenticateWithCredential, updateProfile, sendPasswordResetEmail, verifyBeforeUpdateEmail , getAuth} from 'firebase/auth'
import { useState, useCallback, useMemo } from 'react';
import { db } from '../../utility/firebase';




function General() {
  const { theme } = useGlobal();
  const session = useSession();
  const [editing, setEditing] = useState(false);



  return session && <Column style={{alignItems: 'center', maxWidth: 800}}>

    <h3>General</h3>
    
    <Column style={{margin: '20px 10px', border: '1px solid #232323', padding: 20, justifyContent: 'space-between', borderRadius: 10}}>
      <DisplayName />
      <UserID />
      <Email />
      <Password />
    </Column>
  </Column>
};







const DisplayName = () => {
  const { theme } = useGlobal();
  const session = useSession();
  const [editing, setEditing] = useState(false);

  const [newDisplayName, setNewDisplayName] = useState('');
  const [successful, setSuccessful] = useState(false);

  const user = useMemo(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    return user;
  }, []);







  const setDisplayName = useCallback(async () => {
    const auth = getAuth();
    const user = auth.currentUser;
  
    if (!user) {
      console.error("No user is currently signed in.");
      return;
    }
  
    try {
      await updateProfile(user, { displayName: newDisplayName });
      console.log("Display name set successfully.");

      // Step 2: Save to Firestore
    await setDoc(doc(db, "users", user.uid), { displayName: newDisplayName }, { merge: true });
    console.log("Display name persisted to Firestore.");


    } catch (error) {
      console.error("Error setting display name:", error.message);
    };
    setEditing(false);
  }, [newDisplayName, setEditing]);



  return <Column style={{ justifyContent: 'space-between', margin: '10px 0px'}}>
    <Row>
      <b style={{minWidth: 100}}>Display Name</b>
      <div style={{flexGrow: 1}} />
      {editing
        ? <>
          <div style={{color: theme.green}} onClick={setDisplayName}>Save</div>
          <div style={{color: theme.red, marginLeft: 20}} onClick={() => setEditing(false)}>Close</div>
        </>
        : false && <div style={{color: theme.orange}} onClick={() => setEditing(true)}>Edit</div>}
    </Row>
    {!editing && <div style={{}}>{user?.displayName}</div>}
    {editing && <Column>
      <input type='text' placeholder='New Display Name' value={newDisplayName} onChange={((e) => setNewDisplayName(e.target.value))} 
        style={{outline: 'none', background: theme.bg, border: `1px solid ${theme.border}`, borderRadius: 4, color: theme.txt}} />
    </Column>}

    {successful && <p style={{color: theme.green}}>
      Success!
    </p>}
  </Column>
}




const UserID = () => {
  const { theme } = useGlobal();
  const session = useSession();
  const [show, setShow] = useState(false);



  return <Column style={{justifyContent: 'space-between', margin: '10px 0px'}}>
  <Row>
    <b>UserId</b>
    <div style={{flexGrow: 1}} />
    {show
        ? <>
          <div style={{color: theme.red, marginLeft: 20}} onClick={() => setShow(false)}>Hide</div>
        </>
        : <div style={{color: theme.orange}} onClick={() => setShow(true)}>Show</div>}
  </Row>
  {show && <div>{session.user?.uid}</div>}
</Column>;
};







const Email = () => {
  const { theme } = useGlobal();
  const session = useSession();
  const [editing, setEditing] = useState(false);

  const [password, setPassword] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [successful, setSuccessful] = useState(false);



  const handleUpdateEmail = useCallback(async (e) => {
    e.preventDefault(); // Prevent default behavior if tied to a form
  
    const auth = getAuth();
    const user = auth.currentUser; // Firebase's authenticated user
  
    if (!user) {
      console.error("No user is currently signed in.");
      return;
    }
  
    try {
      // Step 1: Reauthenticate the user
      const credential = EmailAuthProvider.credential(user.email, password);
      await reauthenticateWithCredential(user, credential);
      console.log("Reauthenticated successfully.");
  
      // Step 2: Update the email
      await verifyBeforeUpdateEmail(user, newEmail);
      console.log("Email updated successfully.");

      await db.collection("users").doc(user.uid).update({
        email: newEmail,
      });
    
  
      setSuccessful(true);
      setEditing(false); // Reset editing state only on success
    } catch (error) {
      console.error("Error changing email:", error.message);
    }
  }, [password, newEmail, setEditing]);



  return <Column style={{ justifyContent: 'space-between', margin: '10px 0px'}}>
    <Row>
      <b style={{minWidth: 100}}>Email</b>
      <div style={{flexGrow: 1}} />
      {editing
        ? <>
          <div style={{color: theme.green}} onClick={handleUpdateEmail}>Update</div>
          <div style={{color: theme.red, marginLeft: 20}} onClick={() => setEditing(false)}>Close</div>
        </>
        : <div style={{color: theme.orange}} onClick={() => setEditing(true)}>Edit</div>}
    </Row>
    {!editing && <div style={{}}>{session.user?.email}</div>}
    {editing && <Column>
      <input type='text' placeholder='New Email' value={newEmail} onChange={((e) => setNewEmail(e.target.value))} 
        style={{outline: 'none', background: theme.bg, border: `1px solid ${theme.border}`, borderRadius: 4, color: theme.txt}} />
      <input type='password' placeholder='Password' value={password} onChange={((e) => setPassword(e.target.value))} 
        style={{outline: 'none', background: theme.bg, border: `1px solid ${theme.border}`, borderRadius: 4, color: theme.txt}} />
    </Column>}

    {successful && <p style={{color: theme.green}}>
      Success! Check your inbox to verify the new email.
    </p>}
  </Column>
}



const Password = () => {
  const { theme } = useGlobal();
  const session = useSession();
  const [editing, setEditing] = useState(false);
  const [successful, setSuccessful] = useState(false);




  const handleSendEmail = useCallback((e) => {
    const resetPassword = async (email) => {
      const auth = getAuth();
    
      try {
        await sendPasswordResetEmail(auth, email);
        console.log("Password reset email sent.");

        setSuccessful(true);
      } catch (error) {
        console.error("Error sending password reset email:", error.message);
      }
    };

    resetPassword(session.user?.email);

    setEditing(false);
  }, [session, setEditing]);




  return <Column style={{ justifyContent: 'space-between', margin: '10px 0px'}}>
    <Row>
      <b style={{minWidth: 100}}>Password</b>
      <div style={{flexGrow: 1}} />
      {editing
        ? <>
          <div style={{color: theme.green}} onClick={handleSendEmail}>Send Email</div>
          <div style={{color: theme.red, marginLeft: 20}} onClick={() => setEditing(false)}>Close</div>
        </>
        : <div style={{color: theme.orange}} onClick={() => setEditing(true)}>Edit</div>}
    </Row>

    {successful && <p style={{flexGrow: 0, color: theme.green, maxWidth: 150}}>
          Success! Check your inbox for a link to update your password.
        </p>}
  </Column>
}







export default General